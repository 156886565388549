import React from 'react';

const FeatureItem = ({ icon, children }) => {
  return (
  
    <div className="grid justify-items-center text-center">
      <img src={icon} alt="" className="h-12 w-12 mb-4" />
      <div>{children}</div>
    </div>

  );
};

export default FeatureItem;
