import React from 'react';

const Headline = ({ children }) => {
  return (
  
    <p className="container mx-auto max-w-3xl text-2xl text-center">
      {children}
    </p>

  );
};

export default Headline;
