import React from 'react';

function Plan({ title, subTitle1, subTitle2, selected = false }) {
  const planStyle = selected
    ? 'p-4 w-full text-center bg-secondary border-t border-b border-lightgrey border-solid'
    : 'p-4 w-full text-center border-t border-b border-lightgrey border-solid';

  return (
    <div className={planStyle}>
      <h3 className="text-white font-strong text-base pb-2">{title}</h3>
      <p className="text-white font-thin text-xs pb-2">{subTitle1}</p>
      <p className="text-white font-strong">{subTitle2}</p>
    </div>
  );
}

export default Plan;
