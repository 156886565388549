import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Section from '../components/Section';
import Container from '../components/Container';
import Contact from '../components/Contact';
import ContainerFlex from '../components/ContainerFlex';
import FlexItem from '../components/ContainerFlex/FlexItem';
import IconItem from '../components/IconItem';
import FeatureItem from '../components/FeatureItem';
import Headline from '../components/Headline';
import Pricing from '../components/Pricing';

import DigestSVG from '../images/animations/digest.svg';
import CompareSVG from '../images/animations/compare.svg';
import DedupeSVG from '../images/animations/dedupe.svg';
import TextlessSVG from '../images/animations/textless.svg';
import MasterSVG from '../images/animations/master.svg';

import cubeLogo from '../images/cube.svg';
import iconPlay from '../images/iconPlay.svg';
import iconTick from '../images/iconTick.svg';
import iconAward from '../images/iconAward.svg';
import api from '../images/api.svg';
import tools from '../images/tools.svg';
import services from '../images/services.svg';
import bbc from '../images/partners/BBC.svg';
import discovery from '../images/partners/Discovery.svg';
import rtl from '../images/partners/RTL.svg';
import * as Scroll from 'react-scroll';

export default function Home() {
  const LinkReact = Scroll.Link;

  useEffect(() => {
    const scrollAnimTriggerCheck = () => {
      const viewBottom = window.scrollY + window.innerHeight;
      document.querySelectorAll('.svg-component').forEach((svg) => {
        const rect = svg.getBoundingClientRect();
        const offsetToTriggerAnimation = rect.y + rect.height;

        if (
          viewBottom > offsetToTriggerAnimation * 1.5 &&
          offsetToTriggerAnimation > 0
        ) {
          svg.querySelectorAll('*[style*="animation:"]').forEach((e) => {
            if (e.classList.contains('inactive')) {
              e.classList.toggle('inactive');
            }
          });
        } else {
          svg.querySelectorAll('*[style*="animation:"]').forEach((e) => {
            if (!e.classList.contains('inactive')) {
              e.classList.toggle('inactive');
            }
          });
        }
      });
    };
    document
      .querySelectorAll('.svg-component *[style*="animation:"]')
      .forEach((e) => e.classList.toggle('inactive'));
    document.addEventListener('scroll', scrollAnimTriggerCheck);

    return () => document.removeEventListener('scroll', scrollAnimTriggerCheck);
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Piksel m3</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Piksel m3 Media Matching Machine" />
        <meta name="keywords" content="Piksel m3 Media Matching Machine" />
        <meta name="author" content="informitv" />
      </Helmet>
      <Hero>
        <img
          src={cubeLogo}
          className="mx-auto py-6"
          alt="Piksel m³ Media Matching Machine"
        />

        <h1 className="text-3xl md:text-6xl font-thin pb-12 text-shadow">
          Media Matching Machine
        </h1>

        <p className="text-2xl text-center text-shadow">
          Piksel&nbsp;m³ analyses audiovisual&nbsp;assets
          and&nbsp;identifies&nbsp;similar&nbsp;features to&nbsp;help&nbsp;you
          manage,&nbsp;prepare&nbsp;and&nbsp;package your media
          for&nbsp;digital&nbsp;delivery.
        </p>

        <p>
          Transform your video workflow and increase productivity with our
          amazing Media&nbsp;Matching&nbsp;Machine.
        </p>

        <div className="flex flex-col md:flex-row justify-center">
          <LinkReact
            to="features"
            className="p-3 cursor-pointer hover:text-white"
            activeClass="activeNav"
            spy={true}
            smooth={true}
            offset={-50}
          >
            <button
              className="min-w-48 pt-3 pb-3 px-10 bg-primary font-strong
						focus:outline-none hover:bg-white hover:text-primary rounded drop-shadow"
              title="Find out how Piksel M3 can help you"
            >
              Learn more
            </button>
          </LinkReact>
          <LinkReact
            to="evaluate"
            className="p-3 cursor-pointer hover:text-white"
            activeClass="activeNav"
            spy={true}
            smooth={true}
            offset={-50}
          >
            <button
              className="min-w-48 pt-3 pb-3 px-10 bg-primary font-strong
						focus:outline-none hover:bg-white hover:text-primary rounded drop-shadow"
              title="Find out how Piksel M3 can help you"
            >
              Evaluate now
            </button>
          </LinkReact>
        </div>
      </Hero>

      <Element name="features">
        <a className="anchor" id="featuresSmall"></a>
        <Section className="bg-secondary">
          <Container>
            <h2 className="text-4xl font-strong py-7 text-center">
              Core Features
            </h2>
            <Headline>
              Our cloud-based services are specifically designed for audiovisual
              media operations.
            </Headline>
          </Container>

          <ContainerFlex>
            <FlexItem>
              <h3>Digest</h3>
              <p>
                We can digest digital media and generate a unique fingerprint
                analysis for each shot, scene, sequence, segment or show.
              </p>
              <p>
                Our intelligent indexing system is based on sound signatures and
                the visual features of images. It is independent of the
                resolution or compression scheme of media files. So you can mix
                and match master materials, mezzanine files, or proxy versions.
              </p>
            </FlexItem>
            <div className="md:flex-1 md:pl-12">
              <DigestSVG alt="Digest digital media files" />
            </div>
          </ContainerFlex>

          <ContainerFlex>
            <FlexItem>
              <h3>Compare</h3>
              <p>
                You can use the digest references to compare media assets and
                find audio and video matches based on their content and
                structure, with confidence scores.
              </p>
              <p>
                Digest references can also be used to identify exactly where
                media files are similar or different, with frame accuracy.
              </p>
              <p>It is like DNA matching for media.</p>
            </FlexItem>
            <div className="md:flex-1 md:pr-12 md:order-first">
              <CompareSVG alt="Compare digital media files" />
            </div>
          </ContainerFlex>
        </Section>
      </Element>

      <Element name="benefits">
        <a className="anchor" id="benefitsSmall"></a>
        <Section>
          <Container>
            <h2 className="text-center">Business benefits</h2>
            <Headline>
              Customers are benefitting from huge efficiency gains by using our
              cloud-based services.
            </Headline>
            <div className="flex flex-col md:flex-row py-7 md:space-x-12">
              <IconItem icon={iconTick}>
                <span>Eliminate redundant files</span>
              </IconItem>
              <IconItem icon={iconTick}>
                <span>Automate repetitive tasks</span>
              </IconItem>
              <IconItem icon={iconTick}>
                <span>Reduce the cost of reversioning</span>
              </IconItem>
              <IconItem icon={iconTick}>
                <span>Accelerate media preparation</span>
              </IconItem>
            </div>
          </Container>
        </Section>
      </Element>

      <Element name="uses">
        <Section className="bg-secondary">
          <a className="anchor" id="usesSmall"></a>

          <Container>
            <h2 className="text-center">Practical Uses</h2>
            <Headline>
              Our simple online services can solve many media asset management
              challenges.
            </Headline>
            <div className="flex flex-col md:flex-row justify-between md:space-x-24 mb-5">
              <FeatureItem icon={iconPlay}>
                <p>
                  Compare fingerprints for files based on their audiovisual
                  composition.
                </p>
              </FeatureItem>
              <FeatureItem icon={iconPlay}>
                <p>
                  Discover duplicate or redundant versions of files to reduce
                  storage requirements.
                </p>
              </FeatureItem>
              <FeatureItem icon={iconPlay}>
                <p>
                  Identify similar sections of related media files to confirm
                  where they correspond.
                </p>
              </FeatureItem>
            </div>
          </Container>
        </Section>

        <Section>
          <ContainerFlex>
            <FlexItem>
              <h3>De-duplication</h3>
              <p>
                Many versions of a programme are typically saved during the
                production process, often with only minor differences.
              </p>
              <p>
                Our systems can help identify multiple instances of the same
                material and locate exactly where similar versions differ. We
                can optionally generate preview files allowing you to review the
                results in context.
              </p>
              <p>
                You can then decide with confidence which versions to keep,
                significantly reducing the cost of long-term storage, backups
                and archiving. The savings can be considerable.
              </p>
            </FlexItem>
            <div className="md:flex-1 md:pl-12">
              <DedupeSVG alt="Deduplicate digital media files" />
            </div>
          </ContainerFlex>

          <ContainerFlex>
            <FlexItem>
              <h3>Textless versions</h3>
              <p>
                International distribution of programmes often requires shots
                with captions and other text to be replaced with clean cover
                ‘textless’ material to allow for dubbing or subtitling in other
                languages.
              </p>
              <p>
                Production masters often include ‘textless’ elements appended
                after the main programme for this purpose.
              </p>
              <p>
                Our tools can identify where text appears anywhere in the image,
                recognise related replacement shots, and align them with frame
                accuracy to recommend relevant edit decisions, significantly
                increasing operational efficiency.
              </p>
            </FlexItem>
            <div className="md:flex-1 md:pr-12 md:order-first">
              <TextlessSVG alt="Manage textless versions" />
            </div>
          </ContainerFlex>

          <ContainerFlex>
            <FlexItem>
              <h3>Media masters</h3>
              <p>
                Our Media Matching Machine can help you prepare and compose
                media library assets for storage and distribution in industry
                standard Interoperable Master Format packages.
              </p>
              <p>
                You can use our tools to identify relevant media elements for
                inclusion in a single master package that can be used to render
                multiple versions or compositions.
              </p>
              <p>
                The result is a vastly simplified distribution workflow,
                dramatically reducing storage requirements.
              </p>
            </FlexItem>
            <div className="md:flex-1 md:pl-12">
              <MasterSVG alt="Manage media masters" />
            </div>
          </ContainerFlex>
        </Section>
      </Element>

      <Section className="bg-secondary">
        <Element name="integration">
          <a className="anchor" id="integrationSmall"></a>
          <Container>
            <h2 className="text-center">Easy Integration</h2>
            <Headline>
              Our tools are easy to integrate in your media workflows.
            </Headline>
            <div className="flex flex-col md:flex-row justify-between md:space-x-12 my-5">
              <div className="pb-5 md:pb-0">
                <img
                  src={api}
                  alt="Application programming interfaces"
                  className="shadow-lg"
                />
                <p className="pt-4 text-l">
                  Use our application programming interfaces to develop your own
                  systems to support your particular workflows.
                </p>
              </div>

              <div className="pb-5 md:pb-0">
                <img
                  src={tools}
                  alt="Combine with other tools"
                  className="shadow-lg"
                />
                <p className="pt-4 text-l">
                  Combine our unique capabilities with other tools to create
                  sophisticated media management solutions.
                </p>
              </div>

              <div className="pb-5 md:pb-0">
                <img
                  src={services}
                  alt="Create custom applications"
                  className="shadow-lg"
                />
                <p className="pt-4 text-l">
                  Commission our professional services experts to create custom
                  applications or systems to solve specific problems.
                </p>
              </div>
            </div>
          </Container>
        </Element>
      </Section>
      <Section className="bg-secondary">
        <Container>
          <Headline>Major media companies use the Piksel platform.</Headline>
          <div className="flex justify-center space-x-12">
            <img src={bbc} alt="BBC" className="" />
            <img src={discovery} alt="Discovery" className="" />
            <img src={rtl} alt="RTL" className="" />
          </div>
        </Container>
      </Section>

      <Section className="bg-secondary">
        <Container>
          <div className="flex flex-col md:flex-row justify-between md:space-x-12 my-5">
            <FlexItem>
              <h3>Fast and flexible</h3>
              <p>
                Imagine what you could do with our easy-to-integrate online
                micro services.
              </p>
              <p>
                We will work with media that is already in cloud storage, or you
                can securely submit files for batch processing and receive the
                resulting analysis.
              </p>
              <p>
                Increase your efficiency by plugging in our proven solutions to
                specific media management problems.
              </p>
            </FlexItem>
            <FlexItem>
              <h3>Software service</h3>
              <p>
                There is no hardware or software to install, manage or maintain.
                You can be productive right away.
              </p>
              <p>
                Capable of meeting the most demanding requirements, our systems
                scale automatically, whether you need a few hours a month or
                tens of thousands of hours of video processing.
              </p>
              <p>
                Avoid capital expenditure and reduce your operational costs by
                paying only for the services that you use.
              </p>
            </FlexItem>
          </div>
        </Container>

        <ContainerFlex>
          <FlexItem>
            <FeatureItem icon={iconAward}>
              <h3>Quality</h3>
              <p>
                ISO 9001
                <br />
                Quality Management
                <br />
                Certified since 2012
              </p>
            </FeatureItem>
          </FlexItem>
          <FlexItem>
            <FeatureItem icon={iconAward}>
              <h3>Security</h3>
              <p>
                ISO 27001
                <br />
                Information Security
                <br />
                Certified since 2012
              </p>
            </FeatureItem>
          </FlexItem>
          <FlexItem>
            <FeatureItem icon={iconAward}>
              <h3>Service</h3>
              <p>
                ISO 20000
                <br />
                IT Service Management
                <br />
                Certified since 2005
              </p>
            </FeatureItem>
          </FlexItem>
        </ContainerFlex>
      </Section>

      <Section className="bg-backgroundPages">
        <a className="anchor" id="pricingSmall"></a>
        <Element name="pricing">
          <Container>
            <h2 className="text-center">Example Pricing</h2>
            <Headline>
              Transparent pricing means you only pay&nbsp;per&nbsp;minute of
              media&nbsp;processed.
            </Headline>
            <p className="text-center">
              Pay as you go or lock in lower fixed rates if you have large
              volumes of video to manage monthly.
            </p>
          </Container>
        </Element>

        <Pricing />
      </Section>

      <Section className="bg-secondary">
        <Element name="evaluate">
          <a className="anchor" id="evaluateSmall"></a>

          <Container>
            <h2 className="text-center">Evaluate now</h2>

            <Headline>
              See the capabilities of our Media&nbsp;Matching&nbsp;Machine.
            </Headline>

            <p className="text-center">
              Gain immediate access to our online evaluation&nbsp;lab.
            </p>

            <div className="flex justify-center">
              <ul className="">
                <li className="font-thin list-disc">
                  Explore our examples and see the results.
                </li>
                <li className="font-thin list-disc">
                  Examine the application programming interfaces.
                </li>
                <li className="font-thin list-disc">
                  Submit sample media files for analysis.
                </li>
              </ul>
            </div>
          </Container>

          <Contact />
        </Element>
      </Section>
    </Layout>
  );
}
