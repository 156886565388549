import React, {useState} from 'react';
import CostCalculator from '../CostCalculator';
import { BILLING_PREFERRED, PLAN_USAGE, dataPlans } from '../../data';
import { capitalize, setActive } from '../../helpers';

const Pricing = () => {
  const [billing, setBilling] = useState(BILLING_PREFERRED[1]);

  const billingHandle = (event, billingSelected) => {
    event.preventDefault();
    setBilling(billingSelected);
  };

  const isBillingFixed = () => BILLING_PREFERRED[0] === billing;
  return (
    <React.Fragment>
      <div className="pb-6 flex justify-center">
        <div
          className="border-solid border-4 border-primary border rounded p-1 max-w-max"
          id="groupButtonBilling"
        >
          <button
            onClick={(evt) => {
              billingHandle(evt, BILLING_PREFERRED[1]);
              setActive(evt, 'groupButtonBilling');
            }}
            className="active w-tiny py-1 rounded text-primary font-strong text-sm hover:bg-white hover:text-primary focus:outline-none"
          >
            {capitalize(BILLING_PREFERRED[1])}
          </button>

          <button
            onClick={(evt) => {
              billingHandle(evt, BILLING_PREFERRED[0]);
              setActive(evt, 'groupButtonBilling');
            }}
            className="w-tiny py-1 rounded text-primary font-strong text-sm hover:bg-white hover:text-primary focus:outline-none"
          >
            {capitalize(BILLING_PREFERRED[0])}
          </button>
        </div>
      </div>

      <div>
        {isBillingFixed() ? (
          <p className="pb-6 font-thin text-center">
            Fixed pricing reduces cost for regular monthly requirements.
          </p>
        ) : (
          <p className="pb-6 font-thin text-center">
            Pay as you go pricing is ideal for evaluation or occasional use
          </p>
        )}
      </div>

      <CostCalculator
        planSelected={dataPlans[PLAN_USAGE[0]]}
        billingSelected={billing}
      />
    </React.Fragment>
  );
};

export default Pricing;