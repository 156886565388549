import React from 'react';

import Plan from '../Plan';

function PricingPlans({ billingSelected, itemsPlan = [] }) {
  return (
    <div className="pt-8 flex justify-center">
      <div className="flex-grow  sm:inline-flex">
        {itemsPlan.map((plan) => (
          <Plan
            key={plan.key}
            billingSelected={billingSelected}
            title={plan.title}
            price={plan.price}
            minutes={plan.minutes}
            subTitle1={plan.subTitle1}
            subTitle2={plan.subTitle2}
            selected={plan.selected}
          />
        ))}
      </div>
    </div>
  );
}

export default PricingPlans;
