import React from 'react';

function Contact() {
  return (
    <div className="flex justify-center text-center pb-32">
      {/* Piksel to add code for this form to submit and generate invitation email*/}
      <form>
        <input
          id="email"
          type="email"
          name="email"
          className="outline-none w-5/12 py-3 mr-5 px-5 bg-backgroundPages
					text-white text-sm font-thin"
          placeholder="Enter your email address"
        />
        <button
          className="min-w-48 py-3 px-10 bg-primary
				text-white font-strong text-sm rounded
				focus:outline-none hover:bg-white hover:text-primary"
          title="Enter your email address to receive a link"
        >
          Evaluate now
        </button>
        <div className="py-8">
          <p>
            Your contact details will only be used to provide information about
            Piksel m3.
          </p>
        </div>
      </form>
    </div>
  );
}

export default Contact;
