export const rangeValues = [
  { min: 0, max: 24999 },
  { min: 25000, max: 49999 },
  { min: 50000, max: 74999 },
  { min: 75000, max: 100000 }
];

export const dataPlans = {
  hashing: [
    {
      key: '1',
      title: 'Band 1',
      price: 10500,
      pricePerMin: 0.19,
      minutes: 25000,
      subTitle1: '< 24999 min/mo',
      subTitle2: ' $0.19 per min',
      selected: true
    },
    {
      key: '2',
      title: 'Band 2',
      price: 12333,
      pricePerMin: 0.14,
      minutes: 25000,
      subTitle1: '25,000 - 49,999 min/mo',
      subTitle2: ' $0.14 per min',
      selected: false
    },
    {
      key: '3',
      title: 'Band 3',
      price: 20250,
      pricePerMin: 0.12,
      minutes: 25000,
      subTitle1: '50,000 - 74,999 min/mo',
      subTitle2: ' $0.12 per min',
      selected: false
    },
    {
      key: '4',
      title: 'Band 4',
      price: 49000,
      pricePerMin: 0.1,
      minutes: 25000,
      subTitle1: '> 75,000 min/mo',
      subTitle2: ' $0.10 per min',
      selected: false
    }
  ],
  textless: [
    {
      key: '1',
      title: 'Textless 1',
      price: 105,
      pricePerMin: 0.19,
      minutes: 25000,
      subTitle1: '< 24999 min/mo',
      subTitle2: ' $0.19 per min',
      selected: true
    },
    {
      key: '2',
      title: 'Textless 2',
      price: 3455,
      pricePerMin: 0.14,
      minutes: 25000,
      subTitle1: '25,000 - 49,999 min/mo',
      subTitle2: ' $0.14 per min',
      selected: false
    },
    {
      key: '3',
      title: 'Textless 3',
      price: 5678,
      pricePerMin: 0.12,
      minutes: 25000,
      subTitle1: '50,000 - 74,999 min/mo',
      subTitle2: ' $0.12 per min',
      selected: false
    },
    {
      key: '4',
      title: 'Textless 4',
      price: 12333,
      pricePerMin: 0.1,
      minutes: 25000,
      subTitle1: '> 75,000 min/mo',
      subTitle2: ' $0.10 per min',
      selected: false
    }
  ]
};

export const PLAN_USAGE = ['hashing', 'textless'];

export const BILLING_PREFERRED = ['fixed', 'payAsYouGo'];
