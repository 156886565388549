import React from 'react';

const IconItem = ({ icon, children }) => {
  return (
    <div className="flex items-center">
      <img src={icon} alt="" className="h-12 w-12" />
      <div className="pt-1 pl-2 text-l font-thin text-white text-left">{children}</div>
    </div>
  );
};

export default IconItem;
