import React, { useState, useEffect } from 'react';

import PricingPlans from './PricingPlans';
import PricingResult from './PricingResult';

import { setBubble, getRange, resetBubble } from '../../helpers';
import { BILLING_PREFERRED, rangeValues } from '../../data';

function CostCalculator({ planSelected, billingSelected }) {
  const [range, setRange] = useState(0);
  const [itemsPlan, setItemsPlans] = useState(planSelected);
  const [minutes, setMinutes] = useState(0);
  const [overageMinutes, setOverageMinutes] = useState(0);

  useEffect(() => {
    const range = document.querySelector('.range');
    const bubble = document.querySelector('.bubble');

    if (range) {
      range.addEventListener('input', () => {
        setBubble(range, bubble);
      });
      setBubble(range, bubble);
    }
  }, []);

  useEffect(() => {
    const bubble = document.querySelector('.bubble');
    onChangeSliderHandle(0);
    setMinutes(0);
    setOverageMinutes(0);
    resetBubble(bubble);
    setItemsPlans(planSelected);
  }, [billingSelected, planSelected]);

  // const onChangeOverageMinutesHandle = (ovgMinutes) => {
  //   setOverageMinutes(ovgMinutes);
  // };

  const onChangeSliderHandle = (minutes) => {
    setMinutes(minutes);

    const newRange = getRange(minutes, rangeValues);
    if (newRange !== range) {
      let newItems = [...itemsPlan];
      newItems.map((item, index) => {
        if (billingSelected === BILLING_PREFERRED[0]) {
          newRange === index + 1
            ? (newItems[index].selected = true)
            : (newItems[index].selected = false);
        } else {
          newRange >= index + 1
            ? (newItems[index].selected = true)
            : (newItems[index].selected = false);
        }
      });

      setRange(newRange);
      setItemsPlans(newItems);
    }
  };

  return (
    <div>
      <PricingPlans billingSelected={billingSelected} itemsPlan={itemsPlan} />

      {/* Update this div based on selected minutes*/}
      <div>
        <p className="pt-10 font-strong text-white text-center">
          {`${minutes} minutes per month`}
        </p>
      </div>

      <div className="pt-5 text-center">
        {billingSelected === BILLING_PREFERRED[0] ? (
          <p className="text-center text-white text-sm font-thin">
            Set committed minutes of media to be processed per month
          </p>
        ) : (
          <p className="text-center text-white text-sm font-thin">
            Set expected minutes of media to be processed per month
          </p>
        )}
        <div className="pt-4">
          <div className="range-wrap">
            <input
              className="range w-medium"
              type="range"
              min="0"
              max="100000"
              step="1000"
              value={minutes}
              onChange={({ target: { value: radius } }) => {
                onChangeSliderHandle(radius);
              }}
            />
            <output className="bubble"></output>
          </div>
        </div>
      </div>
      {/*
      {billingSelected === BILLING_PREFERRED[0] && (
        <div className="pt-8 text-center">
          <h2 className="text-center text-white text-sm font-strong">
            How many overage minutes per month?
          </h2>
          <h2 className="text-center text-white text-xs font-thin py-1">
            Overage minutes will be fixed at the selected tier price
          </h2>
          <div className="pt-2 flex justify-center items-center">
            <input
              type="number"
              className="outline-none p-1 bg-black text-white text-xs font-thin w-1/12"
              placeholder="overage minutes"
              onChange={(evt) => {
                onChangeOverageMinutesHandle(evt.target.value);
              }}
            />
            <h2 className="pl-2 text-white text-xs font-thin">min/mo</h2>
          </div>
        </div>
      )}
*/}
      {/*
      <PricingPlans billingSelected={billingSelected} itemsPlan={itemsPlan} />
*/}
      <PricingResult
        billingSelected={billingSelected}
        minutes={minutes}
        overageMinutes={overageMinutes}
        plans={itemsPlan.filter((plan) => plan.selected)}
      />
    </div>
  );
}

export default CostCalculator;
