import React, { useState, useEffect } from 'react';

import {
  formatter,
  getPricesPerPlan,
  getTotalPriceFromPlans
} from '../../../helpers';
import { BILLING_PREFERRED } from '../../../data';

function Plan({ billingSelected, minutes, overageMinutes, plans = [] }) {
  const monthlyOverage = overageMinutes * plans[0].pricePerMin;
  const pricesPerPlan = getPricesPerPlan(plans, minutes);
  const [monthlyCost, setMonthlyCost] = useState(0);

  useEffect(() => {
    const newPricesPerPlan = getPricesPerPlan(plans, minutes);
    const totalPrice = getTotalPriceFromPlans(newPricesPerPlan);
    setMonthlyCost(totalPrice);
  }, [plans, minutes]);

  return (
    <>
      {billingSelected === BILLING_PREFERRED[0] ? (
        <div className="pt-8 pb-12 flex justify-center">
          <div className="w-10/12 lg:w-1/2">
            <div className="pb-3">
              <div className="w-full inline-flex text-right">
                <div className="w-1/2 text-white font-thin text-sm">
                  {`${formatter.format(minutes)} committed monthly minutes`}
                </div>
                <div className="w-1/2 text-white font-thin text-sm">{`$${formatter.format(
                  (plans[0] && plans[0].pricePerMin * minutes) || 0 )}`}
                </div>
              </div>
            </div>
            <hr className="border-lightgrey" />
            <div className="py-2 text-right w-full inline-flex">
              <div className="w-1/2 text-white font-strong text-base">
                Total monthly cost
              </div>
              <div className="w-1/2 text-white font-strong text-base">
                {`$${formatter.format(
                  monthlyOverage +
                    ((plans[0] && plans[0].pricePerMin * minutes) || 0)
                )}`}
              </div>
            </div>
            <hr className="border-lightgrey" />
            <div className="pt-2 text-right w-full inline-flex">
              <div className="w-1/2 text-white font-thin text-sm">
                Annual commitment
              </div>
              <div className="w-1/2 text-white font-thin text-sm">
                {`$${formatter.format(
                  (plans[0] && plans[0].pricePerMin * minutes * 12) || 0
                )}`}
              </div>
            </div>
            <div className="text-right w-full inline-flex">
              <div className="w-1/2 text-white font-thin text-sm">
                Additional minutes @{' '}
                {`$${formatter.format(
                  (plans[0] && plans[0].pricePerMin) || 0
                )}`}{' '}
                per min
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-8 pb-12 flex justify-center">
          <div className="w-10/12 lg:w-1/2">
            <div className="pb-3">
              {pricesPerPlan.map((plan) => (
                <div key={plan.key} className="text-right">
                  <div className="w-full inline-flex">
                    <div className="w-1/2 text-white font-thin text-sm">
                      {`${formatter.format(plan.minutes)} minutes @ ${
                        plan.title
                      }`}
                    </div>
                    <div className="w-1/2 text-white font-thin text-sm">
                      {`$${formatter.format(plan.price)}`}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <hr className="border-lightgrey" />
            <div className="py-2 text-right w-full inline-flex">
              <div className="w-1/2 text-white font-strong text-base">
                Total monthly cost
              </div>
              <div className="w-1/2 text-white font-strong text-base">
                {`$${formatter.format(monthlyCost)}`}
              </div>
            </div>
            <hr className="border-lightgrey" />
            <div className="pt-2 text-right w-full inline-flex">
              <div className="w-1/2 text-white font-thin text-sm">
              {/*Piksel to code this*/}
                {`Additional minutes @ $XXXX per min`}
              </div>
              <div className="w-1/2"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Plan;
