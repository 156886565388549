import React from 'react';
const Hero = ({ children }) => {
  return (
  
		<div className="min-h-screen bg-heroImage bg-center bg-cover mx-auto
			flex items-center justify-center text-center pt-20 pb-10">

			<div className="w-3/4 md:w-1/2">
			
				{children}

			</div>

		</div>  

  );
};

export default Hero;
